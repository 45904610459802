import React from "react"
import { InputField } from "./style"

type Props = {
  placeholder: string
  value: string
  name?: string
  className?: string
  margin?: string
  onChange: any
}

const index = (props: Props) => {
  const { placeholder, name, className, margin, value, onChange } = props

  return (
    <InputField
      name={name}
      className={className}
      placeholder={placeholder}
      margin={margin}
      value={value}
      onChange={onChange}
    />
  )
}

export default index

import React, { useState, useEffect, useRef } from "react"
import Hero, { CarouselItem } from "../components/Layout/Hero"
import Content from "../components/Layout/Content"
import InputField from "../components/InputField"
import TextArea from "../components/TextArea"
import { Button } from "../components/Button/style"
import { Subheading, Heading } from "../components/Layout/Hero/style"
import bgImage from "../../static/img/Contact[5170].png"
import SEO from "../components/SEO"
import emailjs from "@emailjs/browser"
import LoadingIndicator from "../components/LoadingIndicator"

const carouselItem: CarouselItem[] = [
  {
    backgroundImg: bgImage,
    heading: {
      text: "CONTACT US",
      mobileFontAlign: "center",
      desktopFontAlign: "center",
      desktopMargin: "350px 0 0 0",
      mobileMargin: "195px 0 0 0",
      fontColor: "#FCFBFB",
      desktopFontSize: "96px",
    },
  },
]

const inputFieldMargin = "0 0 15px 0"

const ContactUs = () => {
  const form = useRef<HTMLFormElement | null>(null)
  const [hasError, setError] = useState(false)
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isFormValid, setFormValid] = useState(false)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("")
  const [company, setCompany] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    if (document) {
      isLoading
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "unset")
    }
  }, [isLoading])

  /**
   * Submits a valid form upon button click.
   */
  const submitForm = async (e?: any) => {
    // TODO - Implement form submission logic
    if (e) e.preventDefault()
    try {
      if (!form.current) throw new Error()
      setLoading(true)
      emailjs.sendForm(
        "service_v6gr5lp",
        "contact_submission",
        form.current,
        "user_ObkDNFKX2Tb5f1jOMIQWx"
      )
      setFormSubmitted(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert("Error submitting form, please try again.")
    }
  }

  /**
   * Verifies whether an email is valid using OWASP approved REGEX.
   * @param e event object containing target information.
   * @returns boolean indicating if email is valid or not.
   */
  const isEmailValid = (e: any) => {
    setEmail(e.target.value)
    if (
      /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/.test(
        e.target.value
      )
    ) {
      setError(false)
      return true
    }
    setError(true)
    return false
  }

  /**
   * Verifies whether all fields in the form are valid or not.
   * @returns boolean indicating whether the form is valid or not.
   */
  const isValidForm = () => {
    if (fullName && email && country && company && message) {
      if (!hasError) {
        setFormValid(true)
        return true
      }
    }
    setFormValid(false)
    return false
  }

  /**
   * Handles updating the form field values whenever there is a change in input.
   * @param e event object containing target information.
   */
  const onFormChange = (e: any) => {
    switch (e.target.name) {
      case "contact_name":
        setFullName(e.target.value)
        break
      case "contact_email":
        setEmail(e.target.value)
        break
      case "contact_country":
        setCountry(e.target.value)
        break
      case "contact_company":
        setCompany(e.target.value)
        break
      case "contact_message":
        setMessage(e.target.value)
      default:
        break
    }
  }

  useEffect(() => {
    isValidForm()
  }, [fullName, email, country, company, message])

  return (
    <>
      <SEO title="Tangho - Contact Us" description="" url="/contact" />
      {isLoading ? (
        <LoadingIndicator
          mobileHeight={80}
          mobileWidth={80}
          desktopHeight={150}
          desktopWidth={150}
        />
      ) : null}
      <Hero carouselItems={carouselItem} mobileHeight={432} />
      {isFormSubmitted ? (
        <Content maxWidth="100%">
          <Heading
            desktopFontSize="64px"
            mobileFontSize="36px"
            mobileMargin="40px 0 0 0"
            desktopMargin="280px 0 0 0"
          >
            THANK YOU FOR CONTACTING US
          </Heading>
          <Subheading
            mobileFontSize="24px !important"
            mobileLineHeight={31}
            desktopFontSize="24px !important"
            desktopLineHeight={31}
            mobileFontAlign="center !important"
            desktopFontAlign="center !important"
            padded="30px 0"
            mobileMargin="0 0 10px 0"
            desktopMargin="0 0 250px 0"
          >
            A member of our team will be in contact with you shortly.
          </Subheading>
        </Content>
      ) : (
        <Content maxWidth={"805px"}>
          <form
            ref={form}
            onSubmit={submitForm}
            name="contact"
            className="contact-form-flex-wrapper"
          >
            <div className="contact-form-flex-wrapper">
              <InputField
                name="contact_name"
                placeholder="Full Name"
                margin={inputFieldMargin}
                value={fullName}
                onChange={(e: any) => onFormChange(e)}
              />
              <InputField
                name="contact_email"
                className={hasError ? "is-invalid" : ""}
                placeholder="Email"
                margin={inputFieldMargin}
                value={email}
                onChange={(e: any) => isEmailValid(e)}
              />
              <InputField
                name="contact_country"
                placeholder="Country"
                margin={inputFieldMargin}
                value={country}
                onChange={(e: any) => onFormChange(e)}
              />
              <InputField
                name="contact_company"
                placeholder="Company"
                margin={inputFieldMargin}
                value={company}
                onChange={(e: any) => onFormChange(e)}
              />
            </div>
            <TextArea
              name="contact_message"
              placeholder="Your message..."
              margin="0 0 20px 0"
              value={message}
              onChange={(e: any) => onFormChange(e)}
            />
            <Button
              className="contact-send-btn"
              width="100%"
              type="submit"
              disabled={!isFormValid}
              isWhite={true}
            >
              Send
            </Button>
          </form>
        </Content>
      )}
    </>
  )
}

export default ContactUs

import React from "react"
import { TextArea } from "./style"

type Props = {
  placeholder: string
  name?: string
  margin?: string
  value?: string
  onChange?: any
}

const index = (props: Props) => {
  const { placeholder, name, margin, value, onChange } = props
  return (
    <TextArea
      name={name}
      placeholder={placeholder}
      value={value}
      margin={margin}
      onChange={onChange}
    />
  )
}

export default index

import styled from "styled-components"

type inputField = {
  margin?: string
}

export const InputField = styled.input<inputField>`
  display: flex;
  flex-direction: column;
  height: 48px;
  width: 100%;
  font-size: 18px;
  font-family: grafier;
  padding: 12px 10px;
  ${({ margin }) => (margin ? `margin: ${margin};` : "")}
  border: 2px solid #020202;
  border-radius: 2px;

  &.is-invalid {
    border-color: #eb5757;
    outline-color: #eb5757;
  }
`

import React from "react"
import { LoadingModal } from "./styles"

interface Props {
  mobileHeight: number
  desktopHeight: number
  mobileWidth: number
  desktopWidth: number
}
export default function index(props: Props) {
  const { mobileHeight, mobileWidth, desktopHeight, desktopWidth } = props

  return (
    <LoadingModal
      className="modal-background"
      mobileHeight={mobileHeight}
      mobileWidth={mobileWidth}
      desktopHeight={desktopHeight}
      desktopWidth={desktopWidth}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingModal>
  )
}

import styled from "styled-components"

type textArea = {
  margin?: string
}
export const TextArea = styled.textarea<textArea>`
  max-width: 805px;
  height: 234px;
  width: 100%;
  font-size: 18px;
  font-family: grafier;
  padding: 12px 10px;
  ${({ margin }) => (margin ? `margin: ${margin};` : "")}
  border: 2px solid #020202;
  border-radius: 2px;
  resize: none;
`

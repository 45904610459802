import styled from "styled-components"

type loadingModal = {
  mobileHeight: number
  mobileWidth: number
  desktopHeight: number
  desktopWidth: number
}
export const LoadingModal = styled.div<loadingModal>`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  padding-top: ${({ mobileHeight }) =>
    mobileHeight
      ? `calc(50vh - ${mobileHeight / 2 + 10}px)`
      : "calc(50vh - 50px)"};
  padding-bottom: ${({ mobileHeight }) =>
    mobileHeight
      ? `calc(50vh - ${mobileHeight / 2 + 10}px)`
      : "calc(50vh - 50px)"};
  padding-left: ${({ mobileWidth }) =>
    mobileWidth
      ? `calc(50vw - ${mobileWidth / 2 + 10}px)`
      : "calc(50vw - 50px)"};
  padding-right: ${({ mobileWidth }) =>
    mobileWidth
      ? `calc(50vw - ${mobileWidth / 2 + 10}px)`
      : "calc(50vw - 50px)"};

  @media screen and (min-width: 960px) {
    padding-top: ${({ desktopHeight }) =>
      desktopHeight
        ? `calc(50vh - ${desktopHeight / 2 + 10}px)`
        : "calc(50vh - 50px)"};
    padding-bottom: ${({ desktopHeight }) =>
      desktopHeight
        ? `calc(50vh - ${desktopHeight / 2 + 10}px)`
        : "calc(50vh - 50px)"};
    padding-left: ${({ desktopWidth }) =>
      desktopWidth
        ? `calc(50vw - ${desktopWidth / 2 + 10}px)`
        : "calc(50vw - 50px)"};
    padding-right: ${({ desktopWidth }) =>
      desktopWidth
        ? `calc(50vw - ${desktopWidth / 2 + 10}px)`
        : "calc(50vw - 50px)"};
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: ${({ mobileWidth }) => (mobileWidth ? `${mobileWidth}px` : "80px")};
    height: ${({ mobileHeight }) =>
      mobileHeight ? `${mobileHeight}px` : "80px"};

    @media screen and (min-width: 960px) {
      width: ${({ desktopWidth }) =>
        desktopWidth ? `${desktopWidth}px` : "80px"};
      height: ${({ desktopHeight }) =>
        desktopHeight ? `${desktopHeight}px` : "80px"};
    }
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 8px;
    border: 8px solid #3f8070;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3f8070 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
